import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// panelsVisible determines if the topbar and left navigation should be visible

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "Login",
      icon: null,
      panelsVisible: false,
    },
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: () => import("../views/ForgotPassword.vue"),
    meta: {
      title: "Forgot password",
      icon: null,
      panelsVisible: false,
    },
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
    meta: {
      title: "Reset password",
      icon: null,
      panelsVisible: false,
    },
  },
  {
    path: "/activate",
    name: "Activate",
    component: () => import("../views/Activate.vue"),
    meta: {
      title: "Activate",
      icon: null,
      panelsVisible: false,
    },
  },
  {
    path: "/beersubmissions",
    name: "BeerSubmissions",
    component: () => import("../views/BeerSubmissions.vue"),
    meta: {
      title: "Øl indberetninger",
      icon: "mdi-glass-mug",
      panelsVisible: true,
    },
  },
  {
    path: "/",
    name: "BeerTotals",
    component: () => import("../views/BeerTotals.vue"),
    meta: {
      title: "Øl totaler",
      icon: "mdi-glass-mug",
      panelsVisible: true,
    },
  },
  {
    path: "/softdrinksubmissions",
    name: "SoftdrinkSubmissions",
    component: () => import("../views/SoftdrinkSubmissions.vue"),
    meta: {
      title: "Læskedrik indberetninger",
      icon: "mdi-beer",
      panelsVisible: true,
    },
  },
  {
    path: "/softdrinktotals",
    name: "SoftdrinkTotals",
    component: () => import("../views/SoftdrinkTotals.vue"),
    meta: {
      title: "Læskedrik totaler",
      icon: "mdi-beer",
      panelsVisible: true,
    },
  },
  {
    path: "/publishedmonths",
    name: "PublishedMonths",
    component: () => import("../views/PublishedMonths.vue"),
    meta: {
      title: "Publicerede måneder",
      icon: "mdi-calendar",
      panelsVisible: true,
    },
  },
  {
    path: "/companies",
    name: "Companies",
    component: () => import("../views/Companies.vue"),
    meta: {
      title: "Bryggerier",
      icon: "mdi-domain",
      panelsVisible: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("../views/Users.vue"),
    meta: {
      title: "Brugere",
      icon: "mdi-account-group",
      panelsVisible: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
