const ls = window.localStorage;

export default {
  setUser(user) {
    ls.setItem("brygstat-user", JSON.stringify(user));
  },
  setToken(token) {
    ls.setItem("brygstat-token", token);
  },
  getUser() {
    return JSON.parse(ls.getItem("brygstat-user"));
  },
  getToken() {
    return ls.getItem("brygstat-token");
  },
};
