// Parses error messages and returns custom messages for select errors
export function errorMessage(error) {
  if (error.response) {
    return error.response.data.message;
  }
  return _translateMessage(error);
}
function _translateMessage(error) {
  if (
    error.message.toLowerCase().indexOf("network") > -1 &&
    error.message.toLowerCase().indexOf("error") > -1
  ) {
    return "A network error occurred, please check your internet connection.";
  }
  return error;
}
